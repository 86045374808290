import { Injectable } from '@angular/core';
import { DocAIClassificationData, DocAiNotificationError, UploadDocumentDetail } from '@dmv/public/shared/http';
import { FeatureFlagService } from '@libs/feature-flag';

@Injectable({
  providedIn: 'root',
})
export class DocAIClassificationService {
  constructor(private readonly _featureFlagService: FeatureFlagService) {}

  public setNotificationForClassificationFail(detail: UploadDocumentDetail): DocAiNotificationError {
    const notificationError: DocAiNotificationError = { showClose: false };
    notificationError.bodyText = this._getClassificationBodyText(detail);
    notificationError.headerText = 'Possible Incorrect Document Type';
    notificationError.iconClass = 'fail-icon fa-exclamation-triangle';
    notificationError.notificationClass = 'fail-color';
    notificationError.showClose = true;

    return notificationError;
  }

  public findFailingClassification(array: DocAIClassificationData[]): DocAIClassificationData[] {
    return array == null ? [] : array.filter(result => result.isExpectedDocumentType === false);
  }

  private _getClassificationBodyText(detail: UploadDocumentDetail): string {
    const failedClassification =
      detail.classificationScoreDocAiResults?.find(result => result.isExpectedDocumentType === false) ?? ({} as DocAIClassificationData);

    return ['Unknown', 'other', 'Other'].includes(failedClassification.classifiedDocumentTypeName)
      ? 'To avoid potential issues, please upload the correct document or select a different document type from the previous page.'
      : `It appears you have uploaded a ${failedClassification.classifiedDocumentTypeName} instead of a\n` +
          `        ${failedClassification.expectedDocumentTypeName}. Please upload the correct document or ${
            detail.docTypeChangeEligible && this._featureFlagService.isFeatureFlagEnabled('public-doc-ai-change-doc')
              ? 'update your document type below.'
              : 'select a different document type from the previous page.'
          }`;
  }
}
