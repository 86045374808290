import { Injectable } from '@angular/core';
import { UploadStatus } from '@dmv/common';
import {
  DocAiNotificationError,
  DocAiResponse,
  SharedApiService,
  UploadDocumentDetail,
  UploadItem,
  UploadItemResult,
  UploadResponse,
} from '@dmv/public/shared/http';
import { FeatureFlagService } from '@libs/feature-flag';
import { getDocument } from 'pdfjs-dist';
import { Observable, from, interval, of, timer } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil, takeWhile, toArray } from 'rxjs/operators';
import { DocAIClassificationService } from './docai-classification.service';
import { DocAIExtractionService } from './docai-extraction.service';
import { DocAIQualityService } from './docai-quality.service';
import { PdfService } from './pdf.service';

@Injectable({
  providedIn: 'root',
})
export class UploadPanelService {
  constructor(
    private readonly _docaiClassificationService: DocAIClassificationService,
    private readonly _docaiExtractionService: DocAIExtractionService,
    private readonly _docaiQualityService: DocAIQualityService,
    private readonly _featureFlagService: FeatureFlagService,
    private readonly _sharedApiService: SharedApiService,
    private readonly _pdfService: PdfService,
  ) {}

  public getAlertType(detail: UploadDocumentDetail) {
    let type = '';
    switch (detail.status) {
      case UploadStatus.SUCCESS:
        type = 'success';
        break;
      case UploadStatus.UPLOADING:
        type = 'info';
        break;
      case UploadStatus.PENDING:
        type = 'warning';
        break;
      default:
        type = 'danger';
    }

    return type;
  }

  /*
   * Sets text for upload button on a single document side.
   *
   * @param {UploadDocumentDetail} detail - One side of a document.
   * @param {UploadItem} uploadItem - The whole upload item. Contains necessary properties not found on detail such as singleFileChoose.
   * @returns {string} - Text that will be shown for upload button.
   */
  public getUploadTag(detail: UploadDocumentDetail, uploadItem: UploadItem): string {
    let tag = '';
    if (
      this._featureFlagService.isFeatureFlagEnabledFromArray([
        'public-doc-ai-classification',
        'public-doc-ai-extraction',
        'public-doc-ai-quality',
      ])
    ) {
      const tagStart = 'Upload ';

      tag = uploadItem?.singleFileChoose ? this.getTagStart(detail.subTitle, tagStart) : `${tagStart}Documents`;

      if (detail.loading) {
        tag = 'Loading';
      }
      if (detail.uploaded) {
        tag = 'Reupload';
      }

      return tag;
    }

    const tagStart = 'File Upload For ';

    tag = uploadItem?.singleFileChoose ? this.getTagStart(detail.subTitle, tagStart) : 'Choose Files';

    return tag;
  }

  public getTagStart(subtitle: string, tagStart: string) {
    return subtitle?.indexOf('Back') > -1 ? `${tagStart}Back` : `${tagStart}Front`;
  }

  public displayDocAIErrorItem(item: UploadItem) {
    let isError = false;
    item.details.every(detail => {
      isError = this.displayDocAIErrorDetail(detail);
    });

    return isError;
  }

  public displayDocAIErrorDetail(detail: UploadDocumentDetail) {
    return (
      detail.uploaded &&
      ((this._featureFlagService.isFeatureFlagEnabled('public-doc-ai-quality') && detail.qualityScoreDocAiResults?.includes('FAIL')) ||
        (this._featureFlagService.isFeatureFlagEnabled('public-doc-ai-classification') &&
          this._docaiClassificationService.findFailingClassification(detail.classificationScoreDocAiResults).length > 0) ||
        (this._featureFlagService.isFeatureFlagEnabled('public-doc-ai-extraction') &&
          this._docaiExtractionService.findFailingExtraction(detail.extractionScoreDocAiResults).length > 0))
    );
  }

  /*
   * Creates object with information for displaying DocAI Notification
   *
   * @param {UploadDocumentDetail} detail - The information about 1 side of the document upload.
   * @returns {DocAiNotificationError} - Object containing body text and class, header text and class, notification (overall) class, icon class (controls which icon to show), and whether to show close button.
   */
  public getNotificationError(detail: UploadDocumentDetail): DocAiNotificationError {
    let notificationError: DocAiNotificationError = { showClose: false };
    const isQualityFail =
      detail.qualityScoreDocAiResults && detail.qualityScoreDocAiResults.length > 0 && detail.qualityScoreDocAiResults.includes('FAIL');
    const isClassificationFail =
      detail.classificationScoreDocAiResults &&
      detail.classificationScoreDocAiResults.length > 0 &&
      this._docaiClassificationService.findFailingClassification(detail.classificationScoreDocAiResults).length > 0;
    const isExtractionFail =
      detail.extractionScoreDocAiResults &&
      detail.extractionScoreDocAiResults.length > 0 &&
      this._docaiExtractionService.findFailingExtraction(detail.extractionScoreDocAiResults).length > 0;
    if (detail.loading) {
      notificationError = this._setNotificationForLoading();
    } else if (detail.uploaded) {
      if (isQualityFail && this._featureFlagService.isFeatureFlagEnabled('public-doc-ai-quality')) {
        notificationError = this._docaiQualityService.setNotificationForQualityFail();
      } else if (isClassificationFail && this._featureFlagService.isFeatureFlagEnabled('public-doc-ai-classification')) {
        notificationError = this._docaiClassificationService.setNotificationForClassificationFail(detail);
      } else if (isExtractionFail && this._featureFlagService.isFeatureFlagEnabled('public-doc-ai-extraction')) {
        notificationError = this._docaiExtractionService.setNotificationForExtractionFail(detail);
      } else {
        notificationError = this._setNotificationForSuccess(detail);
      }
    } else {
      const errorStatuses = [UploadStatus.MINFILESIZE, UploadStatus.FILESIZE, UploadStatus.PASSWORDPROTECTED, UploadStatus.CORRUPTED_FILE];
      if (errorStatuses.includes(detail.status)) {
        notificationError = this._setNotificationForError(detail);
      }
    }

    return notificationError;
  }

  /*
   * Polls the database for a docAI response for one side of a document.
   * Polling occurs for the length of the timer (whatever value pollDuration is set to), and at an interval as specified in the interval function (1 second)
   * If data is returned and contains docAI results, check the qualityScore string and store that response on the document side object, as well as necessary properties
   * If polling returns an error, act as though document failed quality testing.
   * If polling times out, act as though document succeeded quality testing.
   *
   * Polling returns a result every second. We filter that array to ignore all where docAI response data is null.
   *
   * IMPORTANT: document upload panels depend on the UploadItem having the uploaded property set, regardless of docAI results
   * Each side also needs to have the uploaded/loading properties properly set for button/indicator text, color, and functionality
   *
   * @param {string[]} processors - The list of processors to check for results, ie QUALITY, CLASSIFICATION.
   * @param {string} docAiResultsId - The docAI results ID for this document side.
   */
  public pollForData(processors: string[], docAiResultsId: string, pollDuration = 15): Observable<DocAiResponse[]> {
    return from(processors).pipe(
      mergeMap(processor => {
        return this.pollAPI(processor, docAiResultsId, pollDuration);
      }),
      filter(response => response.data !== null),
      toArray(),
    );
  }

  public pollAPI(processor: string, docAiResultsId: string, pollDuration: number, pollInterval = 1000): Observable<DocAiResponse> {
    return interval(pollInterval).pipe(
      switchMap(() =>
        this._sharedApiService.getProcessorResults(processor, docAiResultsId).pipe(
          map(res => ({ ...res, processor })),
          catchError(error => {
            console.error(error);

            return of({ data: null, processor, resultsId: docAiResultsId });
          }),
        ),
      ),
      takeWhile(response => response.data === null, true), // stops polling when response property contains data
      takeUntil(timer(pollDuration * 1000)), // stops polling after specified duration
    );
  }

  public processUploadItemResult(uploadItemResult: UploadItemResult, uploadItem: UploadItem): void {
    this.setupUploadItem(uploadItemResult, uploadItem);

    const sideDetail: UploadDocumentDetail = uploadItem.details.find(detail => detail.id.includes(uploadItemResult.stagingResponse.side));

    const sideResponseData: UploadResponse = uploadItem.uploadResponseData.find(
      data => data.docAiResultsId === uploadItemResult.stagingResponse.docAiResultsId,
    );

    //uploadItemResult.stagingResponse.docAiResultsId check is true if backend feature flag is on
    //sideResponseData check is defensive programming, but should always find a matching side in the response data
    //uploadItemResult.docAiResults check will be true if document has processors, is not excluded
    if (uploadItemResult.stagingResponse.docAiResultsId && sideResponseData && uploadItemResult.docAiResults) {
      const qualityResult = uploadItemResult.docAiResults.find(res => res.processor === 'QUALITY');
      if (qualityResult) {
        sideDetail.qualityScoreDocAiResults.push(qualityResult.data?.qualityScore || 'PASS');
      } else {
        //case: timeout or doc ai service not running
        sideDetail.qualityScoreDocAiResults.push('PASS');
      }

      const classificationResult = uploadItemResult.docAiResults.find(res => res.processor === 'CLASSIFICATION');
      if (classificationResult && classificationResult.data) {
        sideDetail.classificationScoreDocAiResults.push({
          classifiedDocumentTypeId: classificationResult.data.documentTypeId,
          classifiedDocumentTypeName: classificationResult.data.documentTypeName,
          expectedDocumentTypeName: uploadItem.shortName,
          isExpectedDocumentType: classificationResult.data.expectedDocumentType,
        });
      } else {
        //case: timeout or doc ai service not running
        sideDetail.classificationScoreDocAiResults.push({
          classifiedDocumentTypeId: -1,
          classifiedDocumentTypeName: '',
          expectedDocumentTypeName: uploadItem.shortName,
          isExpectedDocumentType: true,
        });
      }

      const extractionResult = uploadItemResult.docAiResults.find(res => res.processor.includes('EXTRACT'));
      if (extractionResult && extractionResult.data?.topValidationError) {
        sideDetail.extractionScoreDocAiResults.push(
          { validationError: extractionResult.data?.topValidationError?.errorMessage } || { validationError: 'PASS' },
        );
      } else {
        //case: timeout or doc ai service not running
        sideDetail.extractionScoreDocAiResults.push({ validationError: 'PASS' });
      }
    } else {
      sideDetail.qualityScoreDocAiResults.push('PASS');
      sideDetail.classificationScoreDocAiResults.push({
        classifiedDocumentTypeId: -1,
        classifiedDocumentTypeName: '',
        expectedDocumentTypeName: uploadItem.shortName,
        isExpectedDocumentType: true,
      });
      sideDetail.extractionScoreDocAiResults.push({ validationError: 'PASS' });
    }
    this.setSideDetailUploaded(sideDetail, uploadItem.multiFileUpload);
    this.setItemUploaded(uploadItem);
  }

  public setupUploadItem(uploadItemResult: UploadItemResult, uploadItem: UploadItem) {
    uploadItem.stagedDocumentId = uploadItemResult.stagingResponse.stagedId;

    if (!uploadItem.uploadResponseData) {
      uploadItem.uploadResponseData = [];
    }

    uploadItem.uploadResponseData.push(uploadItemResult.stagingResponse);

    uploadItem.originalStageIds = [];
    for (const data of uploadItem.uploadResponseData) {
      uploadItem.originalStageIds.push(data.stagedId);
    }
  }

  public clearNewUploadResponse(item: UploadItem, detail: UploadDocumentDetail, side: string): void {
    //we want to clear out the side response if a 2 sided document, so we can add a new one in its place in processUploadItemResult
    if (item.details.length > 1) {
      item.uploadResponseData = item.uploadResponseData.filter(data => data.side !== side);
    } else {
      //if there is only 1 side, clear out upload response data
      item.uploadResponseData = [];
    }

    detail.qualityScoreDocAiResults = [];
    detail.classificationScoreDocAiResults = [];
    detail.extractionScoreDocAiResults = [];
  }

  public setSideDetailUploaded(detail: UploadDocumentDetail, isMultiFileUpload = false): void {
    //this checks that all files for a single sided, multiple file upload have returned
    //only checks quality score because every file will have something in that array, either a pass or fail
    if (!isMultiFileUpload && detail.files.length > 1 && detail.files.length !== detail.qualityScoreDocAiResults.length) {
      detail.loading = true;
      detail.uploaded = false;
    } else {
      detail.loading = false;
      detail.uploaded = true;
      //this status is for FE feature flag off, just says the side successfully uploaded
      detail.status = UploadStatus.SUCCESS;
    }
  }

  public setItemUploaded(uploadItem: UploadItem): void {
    let allDetailsUploaded = true;
    for (const detail of uploadItem.details) {
      if (!detail.uploaded) {
        allDetailsUploaded = false;
      }
    }

    if (allDetailsUploaded) {
      //this uploaded property MUST be set to true for our document upload workflows to properly function.
      //the document will not be marked as uploaded until this is set
      uploadItem.uploaded = true;
    }
  }

  /*
   * Checks if new files to upload are the same as the files that were already uploaded
   * For a 2 sided document, this checks if a front/back image was already uploaded as the opposite side
   *
   * @param {File[]} files - New file(s) to be uploaded for one side of a document.
   * @param {UploadItem} uploadItem - The whole upload item (both sides if applicable).
   * @returns {boolean} - Is this file or array of files unique vs the already uploaded file(s) for this upload.
   */
  public isUniqueFile(files: File[], uploadItem: UploadItem): boolean {
    return uploadItem.details.every(detail => {
      if (uploadItem.details.length === 1) {
        //only if one sided file
        let isDuplicate;
        if (files.length === detail.files?.length) {
          isDuplicate = detail.files?.every(detailFile =>
            files.find(file => file.name === detailFile.name && file.size === detailFile.size),
          );
        } else {
          //if there is a different number of new images vs already uploaded file
          isDuplicate = false;
        }

        return !isDuplicate;
      }

      return !detail.files
        ? true
        : detail.files.every(uploaded => {
            let isDuplicate = false;
            files.forEach(file => (isDuplicate = !isDuplicate && file.name === uploaded.name && file.size === uploaded.size));

            return !isDuplicate;
          });
    });
  }

  public getItemErrorMsg(item: UploadDocumentDetail, uploadedFileNames: File[], currentFileName: string): string {
    let errorString = null;

    if (item?.status) {
      switch (item.status) {
        case UploadStatus.REQUIRED:
          errorString = `Error: ${item.subTitle} document is required. ${item.instructions ? item.instructions : ''}`;
          break;
        case UploadStatus.FAILED:
          errorString = `Error: ${item.subTitle} upload failed. Please re-upload document.`;
          break;
        case UploadStatus.BARCODED_INVALID:
          errorString = 'Error: Barcode Not Detected or Is Invalid. Please try again.';
          break;
        case UploadStatus.PAGE_COUNT_EXCEEDED:
          errorString = 'Error: Page count exceeded. Please upload a file less with less than 10 pages.';
          break;
        case UploadStatus.FILESIZE:
          errorString = `Error: ${item.subTitle}. ${UploadStatus.FILESIZE}`;
          break;
        case UploadStatus.MINFILESIZE:
          errorString = `Error: ${item.subTitle}. ${UploadStatus.MINFILESIZE}`;
          break;
        case UploadStatus.PASSWORDPROTECTED:
          errorString = `Error: ${item.subTitle}. ${UploadStatus.PASSWORDPROTECTED}`;
          break;
        case UploadStatus.CORRUPTED_FILE:
          // eslint-disable-next-line max-len
          errorString = `Error: The file you uploaded is corrupt, if it is an image file, attempt to capture it again with another method or take a screenshot of your image and upload the screenshot.`;
          break;
        default: {
          if (currentFileName === null && item.files) {
            currentFileName = item.files[0]?.name;
          }
          // Break out logic into readable chunks.  Not perfect, but better than reading how it was before.
          // Safely reference potentially undefined fields.
          let description = '';
          if (item.files && item.files[0] && item.files[0].name) {
            description = `${item.status} ${item.files[0].name}`;
          } else {
            description = `${item.status} `;
            if (
              uploadedFileNames &&
              uploadedFileNames[0] &&
              uploadedFileNames[0].name === currentFileName &&
              uploadedFileNames.length > 1
            ) {
              description += uploadedFileNames[1]?.name;
            } else {
              description += uploadedFileNames[0]?.name;
            }
          }

          errorString = description;
        }
      }
    }

    return errorString;
  }

  public async isValidFile(file: File, validDocTypes: string[], selectedDetail: UploadDocumentDetail) {
    let isValid = false;
    if (!file) {
      selectedDetail.status = UploadStatus.INVALID;
    } else if (!validDocTypes || validDocTypes.length === 0) {
      isValid = true;
    } else {
      const fileName = file.name;
      const ext = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();

      if (!validDocTypes.includes(ext)) {
        selectedDetail.status = UploadStatus.INVALID;
      } else if (['jpg', 'png', 'jpeg'].includes(ext)) {
        isValid = await this._validateImageFile(file, selectedDetail);
      } else if (ext === 'pdf') {
        isValid = await this._validatePdfFile(file, selectedDetail);
      } else {
        isValid = true;
      }
    }

    return isValid;
  }

  private _setNotificationForLoading(): DocAiNotificationError {
    const notificationError: DocAiNotificationError = { showClose: false };
    notificationError.bodyClass = 'loading-body';
    notificationError.bodyText = 'We are analyzing your uploads to ensure they meet the requirements.';
    notificationError.notificationClass = 'loading-color';

    return notificationError;
  }

  private _setNotificationForSuccess(detail: UploadDocumentDetail): DocAiNotificationError {
    const notificationError: DocAiNotificationError = { showClose: false };
    notificationError.bodyClass = 'success-text';
    notificationError.bodyText =
      detail.files.length > 1
        ? `${detail.files.length} documents have been uploaded and no issues were detected.`
        : 'Your document has been uploaded and no issues were detected.';
    notificationError.headerClass = 'success-text';
    notificationError.headerText = 'Successful Upload';
    notificationError.iconClass = 'success-icon fa-check-circle';
    notificationError.notificationClass = 'success-color';
    notificationError.showClose = true;

    return notificationError;
  }

  private _setNotificationForError(detail: UploadDocumentDetail): DocAiNotificationError {
    const notificationError: DocAiNotificationError = { showClose: false };
    notificationError.bodyText = detail.status;
    notificationError.headerText = 'Error:';
    notificationError.headerClass = 'error-text';
    notificationError.iconClass = 'error-icon fa-exclamation-triangle';
    notificationError.notificationClass = 'error-color';

    return notificationError;
  }

  private async _validatePdfFile(file: File, selectedDetail: UploadDocumentDetail): Promise<boolean> {
    if (!this._featureFlagService.isFeatureFlagEnabled('public-pdf-password-protected-check')) {
      return true;
    }

    const pdfData = new Uint8Array(await file.arrayBuffer());
    const blob = new Blob([pdfData], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    let isValid = true;

    return getDocument(url)
      .promise.then(() => {
        return true; // if PDF loads successfully
      })
      .catch(error => {
        console.error('Error loading PDF.  Error: ', JSON.stringify(error));

        if (error.message === 'No password given') {
          selectedDetail.status = UploadStatus.PASSWORDPROTECTED;
        } else {
          selectedDetail.status = UploadStatus.CORRUPTED_FILE;
        }

        isValid = false;

        return isValid; // return false if there's an error
      });
  }

  private async _validateImageFile(file: File, selectedDetail: UploadDocumentDetail) {
    const isValid = await this._pdfService.validateImage(file);
    if (!isValid) {
      selectedDetail.status = UploadStatus.CORRUPTED_FILE;
    }

    return isValid;
  }
}
