$fontRegular: 'ProximaNova';
$fontBold: 'ProximaNovaBold';
$fontSemiBold: 'ProximaNovaSemiBold';
$globalFont: 'ProximaNova, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;';
$baseFontSize: 16px;

@for $i from 10 through 40 {
  $fontsize: 0.0625rem * $i;
  .font-size-#{$i} {
    font-size: $fontsize;
  }
}
