import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  FindTransactionsParams,
  OriginalRegistrationListDTO,
  OriginalRegistrationModel,
  RegistrationCancellationDTO,
  SearchResult,
  UpdateHoldModel,
} from '../models';
import { ValidateUtil } from '../utils';

export interface FullfillmentRequest {
  mvrVin?: string;
  initials: string;
  office: string;
  plates?: string;
  fulfiledOn?: Date;
}

@Injectable({ providedIn: 'root' })
export class OriginalRegistrationService {
  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  public transactionPath = 'mvr/v2/original-registrations';

  constructor(private readonly _http: HttpClient, @Inject('env') private readonly _env) {}

  public findRegistrations(paramsToValidate: FindTransactionsParams): Observable<SearchResult<OriginalRegistrationListDTO>> {
    return this._http.get<SearchResult<OriginalRegistrationListDTO>>(`${this._env.apiUrl}/${this.transactionPath}`, {
      params: ValidateUtil.validateHttpParams(paramsToValidate),
    });
  }

  public getRegistration(id: string): Observable<OriginalRegistrationModel> {
    return this._http.get<OriginalRegistrationModel>(`${this._env.apiUrl}/${this.transactionPath}/${id}`);
  }

  public approveTransaction(id: string, fullFillmentData: FullfillmentRequest): Observable<void> {
    return this._http.post<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}/approve`, fullFillmentData);
  }

  public rejectTransaction(id: string, message: string): Observable<void> {
    return this._http.post<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}/reject`, { rejected: true, rejectionReason: message });
  }

  public cancelTransaction(id: string, message: RegistrationCancellationDTO): Observable<void> {
    return this._http.post<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}/cancel`, {
      cancelInitials: message.cancelInitials,
      cancellationReason: message.cancellationReason,
      cancelled: true,
    });
  }

  public unRejectTransaction(id: string): Observable<void> {
    return this._http.post<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}/reject`, { rejected: false });
  }

  public requestDocuments(id: string, content: string): Observable<void> {
    return this._http.post<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}/requestDocuments`, { content });
  }

  public setTransactionStatus(id: number, status: string): Observable<void> {
    return this._http.post<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}/status`, { status });
  }

  public updateHoldStatus(id: string, updateHoldModel: UpdateHoldModel): Observable<void> {
    return this._http.patch<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}`, updateHoldModel);
  }

  public addViewAction(id: string): Observable<void> {
    return this._http.post<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}/view`, this.httpOptions);
  }

  public confirmPickedUpPlates(id: string): Observable<void> {
    return this._http.post<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}/confirmPickedUpPlates`, null, this.httpOptions);
  }

  public markUnsuccessfulVisit(id: string, type: string): Observable<void> {
    return this._http.post<void>(`${this._env.apiUrl}/${this.transactionPath}/${id}/markUnsuccessfulVisit`, { type }, this.httpOptions);
  }
}
