@use '@dmv/theme/partials/variables' as var;

.blued {
  color: var.$color-lightBlue;
}

.show-checklist {
  cursor: pointer;
}

.no-checklist {
  display: none;
}

.tooltip-container-docai-checklist,
.tooltip-inner {
  background-color: white;
  max-width: 450px;
}

.doc-ai-checklist-popover {
  position: relative;

  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: #555;
    padding: 0;
    margin: 0;
    line-height: 1;
  }

  .close-button:hover {
    color: #000; /* Darken the color on hover */
  }

  background-color: white;
  border: 1px solid var.$color-lightBlue;
  border-left: 4px solid var.$color-lightBlue;
  border-radius: 4px;
  color: black;
  text-align: left;
  padding: 5px;

  b {
    padding-left: 5px;
  }

  .PASSED {
    color: var.$color-green;
    font-weight: bold;
  }

  .FAILED {
    color: var.$color-red;
    font-weight: bold;
  }

  .SKIPPED {
    color: var.$color-warning;
    font-weight: bold;
  }
}
